import React from 'react';
import isString from 'lodash/isString';

import { gettext } from '@eventbrite/i18n';
import { getAnalyticsClickHandler } from '@eventbrite/site-analytics';

import { ANCHOR_LINK, DIVIDER } from '@eventbrite/eds-nav-list-item';
import { VerticalDotsChunky } from '@eventbrite/eds-iconography';

import {
    ACTION_DISCOVER,
    ACTION_HELP_LINK_CLICK,
    ACTION_HELP_LINK_HOVER,
    ACTION_HOST_AN_EVENT_CLICK,
    ACTION_HOST_AN_EVENT_HOVER,
    ACTION_FIND_AN_EVENT_CLICK,
    ACTION_FIND_AN_EVENT_HOVER,
    ACTION_ORGANIZE_AN_EVENT_CLICK,
    ACTION_ORGANIZE_AN_EVENT_HOVER,
    ACTION_SIGNIN_CLICK,
    ACTION_LOGIN_HOVER,
    ACTION_LOGO_HOME,
    ACTION_SIGNUP_CLICK,
    CATEGORY_HEADER,
    LABEL_CREATE_EVENT,
    LABEL_ORGANIZE_EVENT,
    LABEL_FIND_EVENT,
} from '../constants';
import { getUserMenuItemsLookup } from './user';

// #3D64FF is the hex value for $ui-blue, the standard blue used for links at EB
export const UI_BLUE = '#3D64FF';

export const isHomepage = (requestPath) => requestPath === '/';

/**
 * Generates data needed to pass as `logoInfo` prop to EDS Global Header
 * @param {string} url URL to navigate to when logo is clicked
 * @param {string} requestPath Current request path (for analytics)
 * @param {object} [logoOptions={}] Additional options matching the EDS Global Header `logoInfo` prop
 * @returns {object}
 */
export const getLogoInfo = (url, requestPath, logoOptions = {}) => ({
    url,

    onClick: getAnalyticsClickHandler({
        requestPath,
        action: ACTION_LOGO_HOME,
        category: CATEGORY_HEADER,
    }),

    ...logoOptions,
});

const knownOrganizerManageUrlPathsRegexp =
    /^\/(affiliates|facebook-publish|links|manage\/events|myevent|organizations|questions)/;

/**
 * Generates a link for the logo based on the current context.
 *
 * Generally, this helps Music customers get back to their events listing
 * rather than the consumer homepage.
 *
 * @param {string} user         The currently logged-in user
 * @param {string} fallbackUrl  The original URL to fall back on
 * @param {string} requestPath  The current path the user is navigating from
 */
export const getLogoInfoUrl = (user, fallbackUrl, requestPath) => {
    if (
        user &&
        user.usesNewPermissions &&
        knownOrganizerManageUrlPathsRegexp.test(requestPath)
    ) {
        return '/organizations/events';
    }

    return fallbackUrl;
};

/**
 * Transforms a desktop link into a mobile link
 *
 * The mobile header takes in a different shape for its links than the desktop header. For example, a type must
 * be present and the href link is named path rather than url
 *
 * @param {string} url The link to where this menu item would bring the user
 * @param {string} content  The label for the link
 */
const _menuItemToNarrowLink = ({ url, content, ...additionalProps }) => ({
    type: ANCHOR_LINK,
    path: url,
    content,
    ...additionalProps,
});

/**
 * Generates links for the mobile header.
 *
 * We have 2 mobile header functions. We added this function as a workaround for the nav experiment
 * the Creator Acquisition team was performing. We have decided to keep the updates to the new nav.
 * TODO: Ideally, this function would be combined with `getNarrowMenuInfo` so we don't have functions
 * with the same task
 *
 * @param {string} serverUrl The current server's base URL e.g. https://evbqa.de
 * @param {string} requestPath  The current path the user is navigating from
 * @param {boolean} createEventCTA  The create event CTA button
 * @param {object} nonUserQuickLinks  The link(s) for logged out users
 */
export const getNarrowMenuLinks = ({
    serverUrl,
    requestPath,
    createEventCTA = null,
    nonUserQuickLinks = null,
    launchNewSignInSignUpLinks = false,
}) => {
    let narrowMenuLinks = [
        {
            type: ANCHOR_LINK,
            path: `${serverUrl}/organizer/overview/`,
            content: gettext('Organize'),
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_HOST_AN_EVENT_CLICK,
                category: CATEGORY_HEADER,
                label: 'Organize',
            }),
            onMouseLeave: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_HOST_AN_EVENT_HOVER,
                category: CATEGORY_HEADER,
                label: 'Organize',
            }),
        },
        {
            type: ANCHOR_LINK,
            path: `${serverUrl}/support/`,
            content: gettext('Help'),
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_HELP_LINK_CLICK,
                category: CATEGORY_HEADER,
                label: 'Help',
            }),
            onMouseLeave: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_HELP_LINK_HOVER,
                category: CATEGORY_HEADER,
                label: 'Help',
            }),
        },
    ];

    if (createEventCTA) {
        narrowMenuLinks = [
            ...narrowMenuLinks,
            _menuItemToNarrowLink(createEventCTA),
        ];
    }

    if (nonUserQuickLinks) {
        narrowMenuLinks = [
            ...narrowMenuLinks,
            { type: DIVIDER },
            _menuItemToNarrowLink(nonUserQuickLinks[0]),
        ];
        if (launchNewSignInSignUpLinks) {
            narrowMenuLinks = [
                ...narrowMenuLinks,
                _menuItemToNarrowLink(nonUserQuickLinks[1]),
            ];
        }
    }

    return {
        fallbackUrl: '#',
        label: gettext('Menu'),
        iconType: <VerticalDotsChunky />,
        navItems: narrowMenuLinks,
    };
};

/**
 * Retrieves list of quick links to pass to `quickLinks` prop of EDS Global Header
 * @param {string} serverUrl Base site URL (e.g. "http://www.eventbrite.com")
 * @param {string} ebDomain Current site domain
 * @param {string} requestPath Current request path (for analytics)
 * @returns {object[]}
 */
export const getQuickLinks = (serverUrl, ebDomain, requestPath) => {
    let quickLinks = [];
    // for non-search pages (including "/events/" city browse pages),
    // show a link to lead the user to the search page
    if (isString(requestPath) && !requestPath.match('^/d/(?!.*/events/)')) {
        quickLinks = [
            ...quickLinks,
            {
                url: `${serverUrl}/d/local/all-events/`,
                content: gettext('Browse Events'),

                onClick: getAnalyticsClickHandler(
                    {
                        requestPath,
                        action: ACTION_DISCOVER,
                        category: CATEGORY_HEADER,
                    },
                    {
                        checkpointName: 'discover_header_link_clicked',
                        domain: ebDomain,
                    },
                ),
            },
        ];
    }

    return quickLinks;
};

/**
 * Gets the info for displaying create event CTA to be passed to `callToActionInfo` prop of EDS Global Header
 * @param {string} serverUrl Base site URL (e.g. "http://www.eventbrite.com")
 * @param {string} ebDomain Current site domain
 * @param {string} requestPath Current request path (for analytics)
 * @param {boolean} isAuthenticated Whether or not the user is authenticated
 * @param {boolean} isExperiment Whether or not the user CTA is experimental
 * @param {object} [dimensions={} showCreateEventCTA={boolean}] Additional GA dimensions for when the CTA is clicked, a flag to determine if the createEventCTA should be rendered
 * @param {function} clickHandler Function that invokes when the element is clicked
 * @returns {object}
 */
export const getCreateEventCTA = (
    serverUrl,
    ebDomain,
    requestPath,
    isAuthenticated,
    isExperiment,
    { showCreateEventCTA = true, dimensions = {} } = {},
    clickHandler,
) => {
    if (!showCreateEventCTA) {
        return null;
    }

    const dataAttributes = {
        'data-automation': 'global-nav-create',
        onClick: () => {
            getAnalyticsClickHandler(
                {
                    requestPath,
                    action: ACTION_HOST_AN_EVENT_CLICK,
                    category: CATEGORY_HEADER,
                    label: LABEL_CREATE_EVENT,
                    dimensions,
                },
                {
                    checkpointName: 'hiw_global_header_cta_clicked',
                    pageWillChange: isAuthenticated,
                    domain: ebDomain,
                },
            )();
            if (clickHandler) {
                clickHandler();
            }
        },
        style: { color: UI_BLUE },
        onMouseLeave: getAnalyticsClickHandler({
            requestPath,
            action: isExperiment
                ? ACTION_ORGANIZE_AN_EVENT_HOVER
                : ACTION_HOST_AN_EVENT_HOVER,
            category: CATEGORY_HEADER,
            label: isExperiment ? LABEL_ORGANIZE_EVENT : LABEL_CREATE_EVENT,
        }),
    };

    return {
        url: `${serverUrl}/create/`,
        content: isExperiment
            ? gettext('Organize an event')
            : gettext('Create an event'),
        ...dataAttributes,
    };
};

/**
 * Gets the info for displaying find event CTA to be passed to `callToActionInfo` prop of EDS Global Header
 * @param {string} serverUrl Base site URL (e.g. "http://www.eventbrite.com")
 * @param {string} ebDomain Current site domain
 * @param {string} requestPath Current request path (for analytics)
 * @param {boolean} isAuthenticated Whether or not the user is authenticated
 * @param {object} [dimensions={} showFindEventCTA={boolean} content={string}] Additional GA dimensions for when the CTA is clicked, a flag to determine if the findEventCTA should be rendered, and the text content of the CTA
 * @param {function} clickHandler Function that invokes when the element is clicked
 * @returns {object}
 */
export const getFindEventCTA = (
    serverUrl,
    ebDomain,
    requestPath,
    isAuthenticated,
    {
        showFindEventCTA = true,
        content = gettext('Find an event'),
        dimensions = {},
    } = {},
    clickHandler,
) => {
    if (!showFindEventCTA) {
        return null;
    }

    const dataAttributes = {
        'data-automation': 'global-nav-create',
        onClick: () => {
            getAnalyticsClickHandler(
                {
                    requestPath,
                    action: ACTION_FIND_AN_EVENT_CLICK,
                    category: CATEGORY_HEADER,
                    label: LABEL_FIND_EVENT,
                    dimensions,
                },
                {
                    checkpointName: 'hiw_global_header_cta_clicked',
                    pageWillChange: isAuthenticated,
                    domain: ebDomain,
                },
            )();
            if (clickHandler) {
                clickHandler();
            }
        },
        style: { color: UI_BLUE },
        onMouseLeave: getAnalyticsClickHandler({
            requestPath,
            action: ACTION_FIND_AN_EVENT_HOVER,
            category: CATEGORY_HEADER,
            label: LABEL_FIND_EVENT,
        }),
    };

    return {
        url: `${serverUrl}/d/local/all-events/`,
        content,
        ...dataAttributes,
    };
};

/**
 * @typedef SearchSuggestion
 * @property {string} value
 * @property {string} content
 */

/**
 * Gets the search information passed to the `searchInfo` prop of EDS Global Header
 * @param {string} serverUrl Base site URL (e.g. "http://www.eventbrite.com")
 * @param {string} query Current search query
 * @param {SearchSuggestion[]} suggestions Search suggestions for auto-complete
 * @param {boolean} hideSearchBar Whether or not to hide the search bar
 * @returns {object}
 */
export const getSearchInfo = (serverUrl, query, suggestions, hideSearchBar) => {
    let searchInfo;

    if (!hideSearchBar) {
        searchInfo = {
            formAction: `${serverUrl}/d/`,
            label: gettext('Search for events'),
            query,
            suggestions,
        };
    }
    return searchInfo;
};

/**
 * Gets list of links usually passed as `nonUserQuickLinks` prop to EDS Global Header
 * @param {string} requestPath Current request path (for analytics)
 * @param {boolean} isAuthenticated Whether or not the user is authenticated
 * @param {string} loginUrl URL to the sign-in page
 * @param {string} signupUrl URL to the sign-up page
 * @returns object[]
 */
export const getNonUserQuickLinks = (
    requestPath,
    isAuthenticated,
    loginUrl,
    signupUrl,
    { launchNewSignInSignUpLinks = false } = {},
) => {
    if (isAuthenticated) {
        return null;
    }
    const loginAction = ACTION_SIGNIN_CLICK;
    const signupAction = ACTION_SIGNUP_CLICK;
    let loginLink = {
        url: loginUrl,
        content: gettext('Sign In'),
        onClick: getAnalyticsClickHandler({
            requestPath,
            action: loginAction,
            category: CATEGORY_HEADER,
        }),
    };
    let signupLink = [];

    loginLink = {
        ...loginLink,
        onMouseLeave: getAnalyticsClickHandler({
            requestPath,
            action: ACTION_LOGIN_HOVER,
            category: CATEGORY_HEADER,
        }),
    };
    if (launchNewSignInSignUpLinks) {
        loginLink = {
            ...loginLink,
            content: gettext('Log In'),
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_SIGNIN_CLICK,
                category: CATEGORY_HEADER,
            }),
        };
        signupLink = [
            {
                url: signupUrl,
                content: gettext('Sign Up'),
                onClick: getAnalyticsClickHandler({
                    requestPath,
                    action: signupAction,
                    category: CATEGORY_HEADER,
                }),
            },
        ];
    }

    return [loginLink, ...signupLink];
};

/**
 * @typedef {object} UrlInfo
 * @property {string} url Help URL
 * @property {string} label Display label
 * @property {string} gaLabel Google Analytics label
 */

/**
 * Retrieves the help menu info typically passed to the `additionalMenus` prop of the EDS Global Header
 * @param {object} helpUrls
 * @param {string} helpUrls.home
 * @param {UrlInfo[]} helpUrls.suggested
 * @param {string} requestPath Current request path (for analytics)
 * @returns object
 */
export const getHelpMenu = ({ home, suggested }, requestPath) => {
    const suggestedHelpMenuItems = suggested.map(({ url, label, gaLabel }) => ({
        type: ANCHOR_LINK,
        path: url,
        content: label,
        onClick: getAnalyticsClickHandler({
            requestPath,
            action: ACTION_HELP_LINK_CLICK,
            category: CATEGORY_HEADER,
            label: gaLabel,
        }),
        onMouseLeave: getAnalyticsClickHandler({
            category: CATEGORY_HEADER,
            action: ACTION_HELP_LINK_HOVER,
            label: gaLabel,
        }),
    }));

    const navItems = [...suggestedHelpMenuItems];

    return {
        label: gettext('Help'),
        fallbackUrl: home,
        navItems,
    };
};

/**
 * Gets info to generate Organizer menu item usually passed `additionalMenus` prop of EDS Global Header
 * @param {object} organizeUrls
 * @param {UrlInfo} organizeUrls.home
 * @param {UrlInfo[]} organizeUrls.suggested
 * @param {string} requestPath Current request path (for analytics)
 * @returns {object}
 */
export const getOrganizeMenu = (
    { home: { url: homeUrl }, suggested },
    requestPath,
) => {
    const suggestedOrganizeMenuItems = suggested.map(
        ({ url, gaLabel, label, accentLink = false }) => {
            // UI_BLUE is the hex value for $ui-blue, the standard blue used for links at EB
            const style = accentLink ? { color: UI_BLUE } : {};
            const link = {
                type: ANCHOR_LINK,
                path: url,
                style,
                content: label,
                onClick: getAnalyticsClickHandler({
                    requestPath,
                    action: ACTION_HOST_AN_EVENT_CLICK,
                    category: CATEGORY_HEADER,
                    label: gaLabel,
                }),
                onMouseLeave: getAnalyticsClickHandler({
                    action: ACTION_HOST_AN_EVENT_HOVER,
                    category: CATEGORY_HEADER,
                    label: gaLabel,
                }),
            };
            return link;
        },
    );
    const navItems = [...suggestedOrganizeMenuItems];

    return {
        label: gettext('Organize'),
        fallbackUrl: homeUrl,
        navItems,
    };
};

/**
 * Retrieves the list of staff-related user menu items given the specified configurations
 * @param {object} options
 * @param {object} options.userMenuItemsLookup Lookup of all user menu items
 * @param {boolean} options.isStaff Whether or not the account is a staff account
 * @param {boolean} options.hasTikibar Whether or not the account has access to Tikibar
 * @returns {object[]}
 */
export const getStaffUserMenuItems = ({
    userMenuItemsLookup,
    isStaff,
    hasTikibar,
}) => {
    if (!isStaff) {
        return [];
    }

    const { admin, tikibar, featureFlags } = userMenuItemsLookup;
    const menuItems = [admin];

    if (hasTikibar) {
        menuItems.push(tikibar);
    }

    menuItems.push(featureFlags, { type: DIVIDER });

    return menuItems;
};

/**
 * Retrieves the list of attendee-related user menu items given the specified configurations
 * @param {object} options
 * @param {object} options.userMenuItemsLookup Lookup of all user menu items
 * @param {number} [options.numFollowing] How many accounts the user is following
 * @param {boolean} options.canUseCredits Whether or not the user has access to credits
 * @param {boolean} options.canUseCollections Whether or not the user has access to collections
 * @param {boolean} options.showBrowseEvents Whether or not to show the "Browse events" menu item
 * @returns {object[]}
 */
export const getAttendeeUserMenuItems = ({
    userMenuItemsLookup,
    numFollowing,
    canUseCredits,
    canUseCollections,
}) => {
    const { tickets, credits, saved, following, collections, interests } =
        userMenuItemsLookup;
    const attendeeItems = [];

    attendeeItems.push(tickets);

    if (canUseCredits) {
        attendeeItems.push(credits);
    }

    attendeeItems.push(saved);
    attendeeItems.push(following);

    if (canUseCollections) {
        attendeeItems.push(collections);
    }

    attendeeItems.push(interests);

    return attendeeItems;
};

const _getManageMyEventsItems = ({
    isAttendee,
    userMenuItemsLookup,
    isMobile,
}) => {
    if (isAttendee && !isMobile) {
        return [];
    }

    const { manageEvents } = userMenuItemsLookup;

    return [manageEvents];
};

export const getBrowseEventsItems = ({
    userMenuItemsLookup,
    showBrowseEvents,
}) => {
    const { browseEvents } = userMenuItemsLookup;

    if (showBrowseEvents) {
        return [browseEvents];
    }
    return [];
};
const _getUserMenuItems = ({
    isMobile,
    adminServerUrl,
    canUseCredits,
    canUseCollections,
    hasTikibar,
    isAttendee,
    isStaff,
    logoutUrl,
    numOrders,
    numSavedEvents,
    numFollowing,
    publicId,
    requestPath,
    serverUrl,
    accountSettingsPath,
    showBrowseEvents,
    email,
    fullName,
    userImageUrl,
    isManageOverviewActive,
}) => {
    const userMenuItemsLookup = getUserMenuItemsLookup({
        adminServerUrl,
        logoutUrl,
        numOrders,
        numSavedEvents,
        numFollowing,
        publicId,
        requestPath,
        serverUrl,
        accountSettingsPath,
        email,
        fullName,
        userImageUrl,
        isManageOverviewActive,
    });
    const staffUserMenuItems = getStaffUserMenuItems({
        userMenuItemsLookup,
        hasTikibar,
        isStaff,
    });
    const browseEventsItems = getBrowseEventsItems({
        userMenuItemsLookup,
        showBrowseEvents,
    });
    const manageEvents = _getManageMyEventsItems({
        userMenuItemsLookup,
        isAttendee,
        isMobile,
    });
    const attendeeMenuItems = getAttendeeUserMenuItems({
        userMenuItemsLookup,
        numFollowing,
        canUseCredits,
        canUseCollections,
        showBrowseEvents,
    });

    return [
        ...staffUserMenuItems,
        ...browseEventsItems,
        ...manageEvents,
        { type: DIVIDER },
        ...attendeeMenuItems,
        { type: DIVIDER },
        userMenuItemsLookup.accountSettings,
        userMenuItemsLookup.logout,
    ];
};

/**
 * Get info for the user menu typically passed to the `userMenuInfo` prop of EDS Global Header
 * @param {object} options
 * @param {string} options.serverUrl Base site URL (e.g. "http://www.eventbrite.com")
 * @param {string} options.adminServerUrl Base Admin site URL (e.gettext. "http://admin.eventbrite.com")
 * @param {string} options.logoutUrl URL to logout page
 * @param {string} options.requestPath Current request path (for analytics)
 * @param {object} options.user User object
 * @param {boolean} options.showBrowseEvents Whether or not to include Browse Events menu item
 * @param {boolean} options.isManageOverviewActive Whether or not the new Manage Overview page is active
 * @param {string} [options.iconColor] Color of the user icon
 */
export const getUserMenuInfo = ({
    serverUrl,
    adminServerUrl,
    logoutUrl,
    requestPath,
    user: {
        hasTikibar,
        isAttendee,
        isAuthenticated,
        isStaff,
        numOrders,
        numSavedEvents,
        numFollowing,
        publicId,
        thumbnailUrl,
        fullName,
        email,
    },
    showBrowseEvents,
    isManageOverviewActive,
    iconColor = undefined,
    featureFlags = {},
    isMobile = false,
}) => {
    if (!isAuthenticated) {
        return null;
    }

    const {
        launchCreatorCreditsSectionAttendee: canUseCredits,
        enableOldCollectionsLinkInHeader: canUseCollections,
    } = featureFlags;

    const accountSettingsPath = 'account-settings';

    return {
        iconColor,
        label: email,
        fallbackUrl: `${serverUrl}/${accountSettingsPath}/`,
        imageUrl: thumbnailUrl,
        navItems: _getUserMenuItems({
            adminServerUrl,
            canUseCredits,
            canUseCollections,
            hasTikibar,
            isAttendee,
            isStaff,
            logoutUrl,
            numOrders,
            numSavedEvents,
            numFollowing,
            publicId,
            requestPath,
            serverUrl,
            accountSettingsPath,
            showBrowseEvents,
            fullName,
            email,
            userImageUrl: thumbnailUrl,
            isManageOverviewActive,
            isMobile,
        }),
    };
};
