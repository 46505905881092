import { CoreApplicationContext } from '@eventbrite/context-gen';
import { getUrlParam, getWindowObject } from '@eventbrite/feature-detection';
import { getLocaleOverride, getParentLocale } from '@eventbrite/i18n';
import omit from 'lodash/omit';
import statsig, { StatsigOptions, StatsigUser } from 'statsig-js';
import {
    DEFAULT_SAMPLE_RATE,
    DEV_ENV_URL_REGEX,
    PROD_ENV_URLS_REGEX,
    QA_ENV_URLS_REGEX,
    STATSIG_ENV,
    STATSIG_OVERRIDE_PARAM,
} from '../constants/constants';
import { StatsigEnvironments } from '../models/statsigEnvironments';
import { getDeviceData } from './device';

const ensureSDKIsInitialized = <SDKValue, DefaultValue>(
    fn: () => SDKValue,
    defaultValue: DefaultValue | null = null,
) => (isStatsigInitialized() ? fn() : defaultValue);

const addValueIfExists = <T>(key: string, value: T) =>
    value ? { [key]: value } : {};

declare global {
    interface Window {
        __SERVER_DATA__?: any;
    }
}

const getUser = (userOptions?: StatsigUser) => {
    const {
        app_version,
        env,
        request,
        user,
        location_slug,
    }: CoreApplicationContext =
        getWindowObject('__SERVER_DATA__') ?? ({} as CoreApplicationContext);
    const { userAgent } = getWindowObject('navigator') ?? {};
    const deviceData = getDeviceData({ userAgent });

    const publicUserOptions: StatsigUser = {
        ...addValueIfExists('userID', userOptions?.userID),
        ...addValueIfExists('ip', userOptions?.ip),
        ...addValueIfExists('country', userOptions?.country),
        ...addValueIfExists('locale', userOptions?.locale),
        ...addValueIfExists('appVersion', userOptions?.appVersion),
        ...addValueIfExists('custom', userOptions?.custom),
        ...addValueIfExists(
            'privateAttributes',
            userOptions?.privateAttributes,
        ),
        ...addValueIfExists('customIDs', userOptions?.customIDs),
    };

    return {
        locale: getParentLocale(getLocaleOverride() || env?.localeInfo?.locale),
        ...(app_version ? { appVersion: app_version } : {}),
        customIDs: {
            ...(request?.correlation_id
                ? { correlationId: request?.correlation_id }
                : {}),
            ...(request?.session_id ? { sessionId: request?.session_id } : {}),
            ...(location_slug ? { pageId: location_slug } : {}),
        },
        ...(user?.publicId ? { userID: user?.publicId } : {}),
        ...(userAgent ? { userAgent } : {}),
        ...(deviceData ? { ...deviceData } : {}),
        ...publicUserOptions,
        // Anything not explicitly set at the top level should be private for PII reasons
        privateAttributes: {
            ...(userOptions?.privateAttributes ?? {}),
            ...omit(userOptions ?? {}, ...Object.keys(publicUserOptions ?? {})),
        },
    };
};

const getStatsigEnv = (): StatsigEnvironments => {
    const { host } = getWindowObject('location') ?? { host: '' };

    if (PROD_ENV_URLS_REGEX.test(host)) {
        return STATSIG_ENV.PROD;
    }
    if (QA_ENV_URLS_REGEX.test(host)) {
        return STATSIG_ENV.QA;
    }
    if (DEV_ENV_URL_REGEX.test(host)) {
        return STATSIG_ENV.DEV;
    }
    return;
};

const getOptions = (options?: StatsigOptions) => ({
    environment: {
        tier: getStatsigEnv(),
    },
    ...options,
});

const getExperimentValueToOverride = (experimentName: string) => {
    const statsigOverrideParam = getUrlParam(STATSIG_OVERRIDE_PARAM);

    if (!statsigOverrideParam || getStatsigEnv() === STATSIG_ENV.PROD) {
        return null;
    }

    const overridenExperiments = (statsigOverrideParam ?? '')
        .split(',')
        .reduce<Record<string, string>>((accum, experimentAndValue) => {
            const [name, value] = experimentAndValue.split(':');

            return {
                ...accum,
                [name]: value,
            };
        }, {});

    return overridenExperiments[experimentName] ?? null;
};

const isStatsigInitialized = () => !!(statsig as any).instance;

const shouldInitializeSDK = (
    sampleRate: number = DEFAULT_SAMPLE_RATE,
    deactivateSdk?: boolean,
) => !deactivateSdk && Math.random() * 100 < sampleRate;

export {
    ensureSDKIsInitialized,
    getExperimentValueToOverride,
    getOptions,
    getUser,
    isStatsigInitialized,
    shouldInitializeSDK,
};
