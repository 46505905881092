import loadable from '@loadable/component';
import React from 'react';
import OldSiteHeader from './OldSiteHeader';

const LazyNewSiteHeader = loadable(() => import('./components/SiteHeader'));

const SiteHeaderPicker = (props: any) => {
    const shouldUseNewHeader = props?.featureFlags?.launchHeader2021;

    let siteHeader = <OldSiteHeader {...props} />;

    if (shouldUseNewHeader) {
        siteHeader = (
            <LazyNewSiteHeader {...props}>
                {({ NewSiteHeader }: any) => <NewSiteHeader {...props} />}
            </LazyNewSiteHeader>
        );
    }

    return siteHeader;
};

export default SiteHeaderPicker;
