import { gettext } from '@eventbrite/i18n';
import {
    CalendarIcon,
    ChevronDownIcon,
    HelpIcon,
    TicketIcon,
} from '@eventbrite/marmalade';
import React from 'react';
import { navigationProps } from '../modules/types';
import { alternativePath } from '../utils';

export const defaultNavigation = ({
    defaultLanguage,
    tld,
}: {
    defaultLanguage: string;
    tld: string;
}): navigationProps => {
    return {
        desktop: [
            {
                path: 'd/local/events/',
                translatedText: gettext('Find Events'),
                heapIdentifier: 'Find Events',
            },
            {
                path: 'organizer/overview/',
                translatedText: gettext('Create Events'),
                heapIdentifier: 'Create Events',
            },
            {
                translatedText: gettext('Help Center'),
                heapIdentifier: 'Help Center',
                iconType: <ChevronDownIcon />,
                iconPosition: 'right',
                dropdownItems: [
                    {
                        path: 'help/',
                        translatedText: gettext('Help Center'),
                        heapIdentifier: 'Help Center',
                    },
                    {
                        path: `help/${defaultLanguage}/articles/319355/where-are-my-tickets/`,
                        translatedText: gettext('Find your tickets'),
                        heapIdentifier: 'Find your tickets',
                    },
                    {
                        path: `help/${defaultLanguage}/articles/647151/how-to-contact-the-event-organizer/`,
                        translatedText: gettext('Contact your event organizer'),
                        heapIdentifier: 'Contact your event organizer',
                    },
                ],
            },
        ],

        mobile: [
            {
                path: 'd/local/events/',
                translatedText: gettext('Find Events'),
                heapIdentifier: 'Find Events',
                iconType: <TicketIcon />,
            },
            {
                translatedText: gettext('Create Events'),
                heapIdentifier: 'Create Events',
                iconType: <CalendarIcon />,
                dropdownItems: [
                    {
                        translatedText: gettext('Solutions'),
                        heapIdentifier: 'Solutions',
                        dropdownItems: [
                            {
                                translatedText: gettext('Event Ticketing'),
                                heapIdentifier: 'Event Ticketing',
                                path: alternativePath('l/sell-tickets/', tld),
                            },
                            {
                                path: 'l/event-marketing-platform/',
                                translatedText: gettext(
                                    'Event Marketing Platform',
                                ),
                                heapIdentifier: 'Marketing Suite',
                            },
                            {
                                path: 'l/eventbrite-ads/',
                                translatedText: gettext('Eventbrite Ads'),
                                heapIdentifier: 'Eventbrite Ads',
                            },
                            {
                                path: 'l/event-payment/',
                                translatedText: gettext('Payments'),
                                heapIdentifier: 'Payments',
                            },
                        ],
                    },
                    {
                        translatedText: gettext('Industry'),
                        heapIdentifier: 'Industry',
                        dropdownItems: [
                            {
                                path: 'l/music/',
                                translatedText: gettext('Music'),
                                heapIdentifier: 'Music',
                            },
                            {
                                path: 'l/food-drink-event-ticketing/',
                                translatedText: gettext('Food & Beverage'),
                                heapIdentifier: 'Food & Beverage',
                            },
                            {
                                path: 'l/performing-arts/',
                                translatedText: gettext('Performing Arts'),
                                heapIdentifier: 'Performing Arts',
                            },
                            {
                                path: 'l/npo/',
                                translatedText: gettext('Charity & Causes'),
                                heapIdentifier: 'Charity & Causes',
                            },
                            {
                                path: 'l/host-retail-events/',
                                translatedText: gettext('Retail'),
                                heapIdentifier: 'Retail',
                            },
                        ],
                    },
                    {
                        translatedText: gettext('Event Types'),
                        heapIdentifier: 'Event Types',
                        dropdownItems: [
                            {
                                path: 'l/music-venues/',
                                translatedText: gettext('Concerts'),
                                heapIdentifier: 'Concerts',
                            },
                            {
                                path: 'l/create-a-workshop/',
                                translatedText: gettext('Classes & Workshops'),
                                heapIdentifier: 'Classes & Workshops',
                            },
                            {
                                path: 'l/festival-solutions/',
                                translatedText: gettext('Festivals & Fairs'),
                                heapIdentifier: 'Festivals & Fairs',
                            },
                            {
                                path: alternativePath('l/conferences/', tld),
                                translatedText: gettext('Conferences'),
                                heapIdentifier: 'Conferences',
                            },
                            {
                                path: 'l/eventbrite-for-business/',
                                translatedText: gettext('Corporate Events'),
                                heapIdentifier: 'Corporate Events',
                            },
                            {
                                path: 'l/virtual-events-platform/',
                                translatedText: gettext('Online Events'),
                                heapIdentifier: 'Online Events',
                            },
                        ],
                    },
                    {
                        translatedText: gettext('Blog'),
                        heapIdentifier: 'Blog',
                        dropdownItems: [
                            {
                                path: 'blog/category/tips-and-guides/',
                                translatedText: gettext('Tips & Guides'),
                                heapIdentifier: 'Tips & Guides',
                            },
                            {
                                path: 'blog/category/news-and-trends/',
                                translatedText: gettext('News & Trends'),
                                heapIdentifier: 'News & Trends',
                            },
                            {
                                path: 'blog/category/community/',
                                translatedText: gettext('Community'),
                                heapIdentifier: 'Community',
                            },
                            {
                                path: 'blog/category/tools-and-features/',
                                translatedText: gettext('Tools & Features'),
                                heapIdentifier: 'Tools & Features',
                            },
                        ],
                    },
                ],
            },
            {
                path: 'organizer/overview/',
                translatedText: gettext('Create Events'),
                heapIdentifier: 'Create Events',
            },
            {
                path: 'l/contact-eventbrite-sales/',
                translatedText: gettext('Contact Sales'),
                heapIdentifier: 'Contact Sales',
            },
            {
                // TODO: Verify if this link always assumes creator intent
                path: 'signin/signup/?referrer=/manage/events/create/',
                translatedText: gettext('Get Started'),
                heapIdentifier: 'Get Started',
                textColor: '#3659E3',
            },
            {
                translatedText: gettext('Help Center'),
                heapIdentifier: 'Help Center',
                iconType: <HelpIcon />,
                dropdownItems: [
                    {
                        path: 'help/',
                        translatedText: gettext('Help Center'),
                        heapIdentifier: 'Help Center',
                    },
                    {
                        path: `help/${defaultLanguage}/articles/319355/where-are-my-tickets/`,
                        translatedText: gettext('Find your tickets'),
                        heapIdentifier: 'Find your tickets',
                    },
                    {
                        path: `help/${defaultLanguage}/articles/647151/how-to-contact-the-event-organizer/`,
                        translatedText: gettext('Contact your event organizer'),
                        heapIdentifier: 'Contact your event organizer',
                    },
                ],
            },
        ],
    };
};
