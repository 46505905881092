import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React from 'react';
import { useGlobalNavContext } from '../../state';
import { getSigninOrSignupUrl } from '../../utils';
import { Logo, ResultsPageSearchBar, SearchBar } from '../common';
import { NavItemList } from './components';
import navStyles from './DesktopNav.module.scss';

export const DesktopNav = () => {
    const { navigation, env, positionSticky } = useGlobalNavContext();
    const { desktop } = navigation ?? {};
    const mainClass = positionSticky?.desktop
        ? classNames(navStyles.main, 'desktopSticky')
        : navStyles.main;

    const isSearchPage: boolean =
        typeof window !== 'undefined' &&
        window?.__SERVER_DATA__?.current_page === 'search';

    return (
        <nav
            aria-label={gettext('Main Desktop').toString()}
            data-testid="desktop-nav"
            className={mainClass}
        >
            <ul className={navStyles.list}>
                {/* Logo */}
                <li className={navStyles.logo} tabIndex={-1}>
                    <Logo />
                </li>
                {/* Search */}
                <li className={navStyles.searchBar}>
                    {isSearchPage ? <ResultsPageSearchBar /> : <SearchBar />}
                </li>
                {/* Nav Items */}
                <li className={navStyles.items}>
                    <NavItemList
                        navItems={desktop}
                        serverUrl={env?.serverUrl ?? ''}
                        signinUrl={getSigninOrSignupUrl(env?.signinUrl)}
                        signupUrl={getSigninOrSignupUrl(env?.signupUrl)}
                    />
                </li>
            </ul>
        </nav>
    );
};

export default DesktopNav;
