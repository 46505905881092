import { useIsMobileDevice } from '@eventbrite/feature-detection';
import { MagnifyingGlassIcon, Typography } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import loadable from '@loadable/component';
import React, { useState } from 'react';
import { STATSIG_SEARCH_BAR_CLICK } from '../../../../constants';
import { useGlobalNavContext } from '../../../../state';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../utils/heap-data';
import styles from './SearchBar.module.scss';

const LazySearchTakeover = loadable.lib(
    () => import('@eventbrite/search-takeover'),
);

/**TODO: Search Page Hierarchy experiment cleanup EB-228109: if this experiment is successful,
 * we should elevate SearchTakeoverRenderer to a single location to avoid multiple re-renders **/

const SearchBar = () => {
    const { Teams, Features, Components, Actions } = HeapAttributes;
    const { searchBar } = useGlobalNavContext();
    const [showSearch, setShowSearch] = useState(false);

    const isMobile = useIsMobileDevice();

    const handleClick = () => {
        logEvent(STATSIG_SEARCH_BAR_CLICK);
        setShowSearch(true);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            logEvent(STATSIG_SEARCH_BAR_CLICK);
            setShowSearch(true);
        }
    };

    function handleClose() {
        setShowSearch(false);
    }

    if (!searchBar?.renderSearchBar) {
        return null;
    }

    return (
        <button
            className={styles.searchBarWrapper}
            data-testid="header-search"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            aria-label={
                isMobile
                    ? searchBar?.mobileLabel?.toString()
                    : searchBar?.desktopLabel?.toString()
            }
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.GlobalNav,
                component: Components.SearchBar,
                name: 'search-bar',
                action: Actions.Click,
            })}
        >
            <>
                <Typography
                    variant="body-md"
                    as="span"
                    color="neutral-700"
                    className={styles.searchBarContent}
                >
                    <MagnifyingGlassIcon size="small" />
                    <span className={styles.desktopLabel}>
                        {searchBar?.desktopLabel}
                    </span>
                    <span className={styles.mobileLabel}>
                        {searchBar?.mobileLabel}
                    </span>
                </Typography>

                {showSearch ? (
                    <LazySearchTakeover data-testid="search-takeover">
                        {({ SearchTakeover }: { SearchTakeover: any }) => (
                            <SearchTakeover
                                domNodeId="root"
                                onClose={handleClose}
                                id="search-takeover"
                                isVisible={showSearch}
                            />
                        )}
                    </LazySearchTakeover>
                ) : null}
            </>
        </button>
    );
};

export default SearchBar;
