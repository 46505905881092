// Type that when passed a union of keys, creates an object which
// cannot have those properties.
type Impossible<K extends keyof any> = { [P in K]: never };

/**
 * This type limits the instance to only keys defined by T.
 *
 * It enforces a strict matching for the given type.
 * If an instance contains additional properties then
 * the type check will fail.
 */
type NoExtraProperties<T, U extends T = T> = U &
    Impossible<Exclude<keyof U, keyof T>>;

export interface TrackEventPayload {
    eventName: string;
    eventData: Object;
}

export interface UpdatePagePropsPayload {
    page: Object;
    eventName?: string;
}

export interface UpdateUserPropsPayload {
    user: Object;
}

export type DatalayerPayload =
    | NoExtraProperties<TrackEventPayload>
    | NoExtraProperties<UpdatePagePropsPayload>
    | NoExtraProperties<UpdateUserPropsPayload>;

export enum EventAction {
    Track = 'Track',
    UpdatePageProps = 'UpdatePageProps',
    UpdateUserProps = 'UpdateUserProps',
}

/**
 * SearchAndDiscoveryPageProps
 *
 * These properties that have been explicitly requested.
 *
 * These properties map directly to column names in snowflake,
 * and so snake-case has been used to aid readability in SQL consumers.
 *
 * These names have also been chosen to avoid possible conflicts with
 * other page or event properties.
 */
export interface SearchAndDiscoveryPageProps {
    search_filter_currencies: string;
    search_filter_date_range: object | string;
    search_filter_dates_relative_to: string;
    deDupedResultsShown: boolean;
    search_filter_include_promoted_events: boolean;
    search_filter_langugages: string;
    search_filter_online_events_only: boolean;
    search_filter_page_number: number;
    search_filter_page_size: number;
    search_filter_price: string;
    search_bar_keywords: string;
    search_filter_following_organizers: string | boolean;
    search_filter_category_id: string;
    search_filter_subcategory_id: string;
    search_filter_tags: string;
    search_filter_format_id: string;
    search_filter_location: object;
}

/**
 * CheckoutPageProps
 *
 * Tehse properties have been explicitly requested.
 *
 * These properties map directly to lowecased names in snowflake.
 */
export interface CheckoutPageProps {
    // Page View Properties
    destinationURL: string;
    pathname: string;
    routerPayload: string;

    // Checkout Flow Related Properties
    isExpressApplePayAvailable: boolean;
    isExpressPayPalAvailable: boolean;

    // Order Related Properties
    orderId: string | null;
    isNOS: boolean;
    totalTicketsSelected: number;
    donationTicketsSelected: number;
    freeTicketsSelected: number;
    paidTicketsSelected: number;
    addonTicketsSelected: number;

    // Event Related Properties
    eventId: string;
    isFree: boolean | undefined;
    seriesId: string;
}
