import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useGlobalNavContext } from '../../state';
import { getSigninOrSignupUrl } from '../../utils';
import {
    Logo,
    MobileDropdown,
    NavLink,
    ResultsPageSearchBar,
    SearchBar,
} from '../common';
import { NavItemType } from '../types';
import styles from './MobileNav.module.scss';

const MobileNav = () => {
    const { positionSticky, env, navigation } = useGlobalNavContext();
    const { mobile } = navigation ?? {};
    const dropdownItems = mobile as NavItemType[];
    const isSticky = positionSticky?.mobile ? 'mobileSticky' : '';
    const [isSearchPage, setIsSearchPage] = useState(false);

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            window?.__SERVER_DATA__?.current_page === 'search'
        ) {
            setIsSearchPage(true);
        }
    }, [isSearchPage]);

    const navStyles = classNames(
        styles.nav,
        styles.navHeight,
        isSticky,
        `${isSearchPage ? `${styles.searchResultsPage}` : ''} `,
    );

    return (
        <nav
            aria-label={gettext('Main Mobile').toString()}
            data-testid="mobile-nav"
            className={navStyles}
        >
            <ul className={styles.root}>
                <li className={styles.logo}>
                    <Logo />
                </li>
                {!isSearchPage && (
                    <li className={styles.searchBarContainer}>
                        <SearchBar />
                    </li>
                )}
                <li className={styles.right}>
                    <ul className={styles.linkContainer}>
                        <li className={styles.listLink}>
                            <NavLink
                                heapIdentifier={'login'}
                                translatedText={gettext('Log In')}
                                path={getSigninOrSignupUrl(env?.signinUrl)}
                                className={styles.login}
                            />
                        </li>
                        <li className={styles.listLink}>
                            <NavLink
                                heapIdentifier={'signup'}
                                translatedText={gettext('Sign Up')}
                                path={getSigninOrSignupUrl(env?.signupUrl)}
                                className={styles.signup}
                            />
                        </li>
                        <MobileDropdown dropdownItems={dropdownItems} />
                    </ul>
                </li>
            </ul>
            {isSearchPage && (
                <div className={styles.searchBarContainer}>
                    <ResultsPageSearchBar />
                </div>
            )}
        </nav>
    );
};

export default MobileNav;
