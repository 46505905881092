import omit from 'lodash/omit';
import React from 'react';
import classNames from 'classnames';
import { ListItem } from '@eventbrite/eds-list-item';

export default class BaseLink extends React.PureComponent {
    componentDidMount() {
        if (this.props.isTentativelySelected) {
            this._focus();
        }
    }

    componentDidUpdate() {
        const hasDropdownFocus = this.props.isTentativelySelected;

        if (hasDropdownFocus !== null) {
            if (hasDropdownFocus) {
                this._focus();
            } else {
                this._blur();
            }
        }
    }

    _getRef(ref) {
        this._link = ref;
        return this._link;
    }

    _blur() {
        if (this._link) {
            this._link.blur();
        }
    }

    _focus() {
        if (this._link) {
            this._link.focus();
        }
    }

    render() {
        const {
            LinkComponent,
            children,
            iconType,
            iconSize,
            iconColor,
            iconStrokeColor,
            imageUrl,
            imageAlt,
            isSquareImage,
            verticalAlignment,
            active,
            useAltTheme,
            isDisabled,
            isTentativelySelected,
            showStatus,
            statusText,
            ...extraProps
        } = this.props;

        let linkProps = omit(extraProps, [
            'onActivate',
            'showStatus',
            'statusText',
        ]);
        const className = classNames(
            'eds-nav-list-item',
            'eds-nav-list-item--link',
            {
                'eds-nav-list-item--selected': active,
                'eds-nav-list-item--alt-theme': useAltTheme,
                'eds-nav-list-item--tentatively-selected':
                    isTentativelySelected,
                'eds-nav-list-item--disabled': isDisabled,
            },
        );

        let Component = LinkComponent;
        let _getRef;

        if (Component === 'a') {
            _getRef = this._getRef.bind(this);
        }

        // if the link is disabled, just render a span tag instead!
        if (isDisabled) {
            Component = 'span';
            linkProps = {};
        }

        return (
            <Component {...linkProps} className={className} ref={_getRef}>
                <ListItem
                    iconType={iconType}
                    iconSize={iconSize}
                    iconColor={iconColor}
                    iconStrokeColor={iconStrokeColor}
                    imageUrl={imageUrl}
                    imageAlt={imageAlt}
                    isSquareImage={isSquareImage}
                    verticalAlignment={verticalAlignment}
                    isSelected={active}
                    useAltTheme={useAltTheme}
                    isDisabled={isDisabled}
                    showStatus={showStatus}
                    statusText={statusText}
                >
                    {children}
                </ListItem>
            </Component>
        );
    }
}
