import { gettext } from '@eventbrite/i18n';
import {
    CardStyle,
    CardViewport,
    ErrorMessage,
    ErrorSeverity,
    LocationError,
} from './types';

export const CARD_VIEWPORT_MOBILE: CardViewport = 'mobile';
export const CARD_VIEWPORT_TABLET: CardViewport = 'tablet';
export const CARD_VIEWPORT_DESKTOP: CardViewport = 'desktop';

export const HISTORY_HASH = '#search';

type ListStyle = 'list';
export const LIST_TYPE: ListStyle = 'list';

export const CARD_STYLE_MINI: CardStyle = 'mini';
export const CARD_STYLE_STANDARD: CardStyle = 'standard';

type ImageStyle = 'square' | 'fluid';
export const IMAGE_STYLE_SQUARE: ImageStyle = 'square';
export const IMAGE_STYLE_FLUID: ImageStyle = 'fluid';

export const ERROR_LOCATION_DENIED: LocationError = 'denied';
export const ERROR_LOCATION_NOT_SUPPORTED: LocationError =
    'permissionNotSupported';
export const ERROR_LOCATION_UNRESOLVED_BROWSER: LocationError =
    'unresolvedBrowserLocation';
export const ERROR_LOCATION_UNRESOLVED_PLACE: LocationError =
    'unresolvedPlaceObject';

export const DISPLAY_TYPE_NEUTRAL: ErrorSeverity = 'neutral';
export const DISPLAY_TYPE_WARNING: ErrorSeverity = 'warning';
export const DISPLAY_TYPE_ERROR: ErrorSeverity = 'error';
export const DISPLAY_TYPES = [
    DISPLAY_TYPE_NEUTRAL,
    DISPLAY_TYPE_WARNING,
    DISPLAY_TYPE_ERROR,
];

export const ERROR_DENIED_USER: ErrorMessage = {
    message: gettext(
        "Looks like we don't have permission to access your location.",
    ).toString(),
    severity: 'neutral',
};

export const ERROR_UNRESOLVED_BROWSER: ErrorMessage = {
    message: gettext(
        "Looks like the browser wasn't able to determine your location.",
    ).toString(),
    severity: 'neutral',
};

export const ERROR_UNRESOLVED_PLACE: ErrorMessage = {
    message: gettext(
        "Looks like we weren't able to resolve your location.",
    ).toString(),
    severity: 'neutral',
};

export const STATSIG_LAZYSEARCH_EVENT = 'LazySearch - All - View - Page';
