import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Label } from '@eventbrite/eds-label';
import { Icon } from '@eventbrite/eds-icon';

import {
    BORDER_HIDDEN,
    BORDER_NONE,
    BORDER_SIMPLE,
    BORDER_CONTENT_DRIVEN,
    STYLE_BASIC,
    BORDER_TYPES_PROP_TYPES,
    STYLES_PROP_TYPES,
    MIN_ASIDE_SPACING_PROP_TYPES,
    MIN_ASIDE_SPACING_PREFIX,
    MIN_ASIDE_SPACING_BOTH,
    MIN_ASIDE_SPACING_SUFFIX,
} from './constants';

import { isStatic } from './utils';

/**
 * Helper component that conditionally renders an annotation note
 * @prop {string} annotationNote - string to be displayed in annotation
 */
const FieldAnnotation = ({ annotationNote }) => {
    let component = null;

    if (annotationNote) {
        component = (
            <aside
                className="eds-field-styled__annotation eds-text-bs eds-fx--fade-in eds-l-pad-top-1"
                data-automation="eds-field-annotation"
                role="alert"
            >
                {annotationNote}
            </aside>
        );
    }

    return component;
};

/**
 * Helper component that conditionally renders a character counter
 * @prop {bool} hasCharacterCount - toggles the character counter
 * @prop {string} value - current value of the input field
 * @prop {integer} maxLength - maximum characters allowed in the input
 */
const CharacterCounter = ({ hasCharacterCount, value, maxLength }) => {
    let component = null;

    if (hasCharacterCount) {
        const currentLength = value.length;

        component = (
            <aside className="eds-field-styled__character-counter eds-text-bs eds-fx--fade-in eds-l-pad-top-1">
                {`${currentLength}/${maxLength}`}
            </aside>
        );
    }

    return component;
};

/**
 * Helper component that conditionally renders a link
 * @prop {string} children - link to display
 */
const StyledLink = ({ children, href }) => {
    let component = null;

    if (children) {
        component = (
            <a
                className="eds-field-styled__link eds-text-bs eds-fx--fade-in eds-l-pad-top-1"
                href={href}
            >
                {children}
            </a>
        );
    }

    return component;
};

/**
 * Helper component that conditionally renders an inline node or text
 * @prop {node} prefix - element to be displayed to the left of the input
 * @prop {node} suffix - element to be displayed to the right of the input
 */
const AsideElement = ({ suffix, prefix, hasMinAsideSpacing, htmlFor }) => {
    let component = null;
    const aside = prefix || suffix;
    const isIcon = aside && aside.type === Icon;
    const className = classNames('eds-field-styled__aside', {
        'eds-field-styled__aside-prefix': prefix,
        'eds-field-styled__aside-suffix': suffix,
        'eds-field-styled__aside--icon': isIcon,
        'eds-field-styled__aside--minimal-spacing': hasMinAsideSpacing,
    });

    if (aside) {
        component = <span className={className}>{aside}</span>;
    }

    // convert aside into clickable element that focuses on input field onclick
    if (aside && htmlFor) {
        component = (
            <label className={className} htmlFor={htmlFor}>
                {aside}
            </label>
        );
    }

    return component;
};

/**
 * Helper component that conditionally renders an inline node or text
 * @prop {annotationNote} FieldAnnotation - element to be displayed to the left of the input
 * @prop {linkSrc} StyledLink - element to be displayed to the right of the input
 * @prop {linkText} StyledLink - element to be displayed to the right of the input
 * @prop {hasCharacterCount} CharacterCounter - element to be displayed to the right of the input
 * @prop {value} CharacterCounter - element to be displayed to the right of the input
 * @prop {maxLength} CharacterCounter - element to be displayed to the right of the input
 */
const FieldSub = ({
    annotationNote,
    linkSrc,
    linkText,
    hasCharacterCount,
    value,
    maxLength,
}) => {
    let fieldSub = null;
    let fieldSubLeft = null;
    let fieldSubRight = null;

    if (annotationNote) {
        fieldSubLeft = (
            <div className="eds-field__sub--left">
                <FieldAnnotation annotationNote={annotationNote} />
            </div>
        );
    }

    if ((linkSrc && linkText) || hasCharacterCount) {
        fieldSubRight = (
            <div className="eds-field__sub--right">
                <StyledLink href={linkSrc}>{linkText}</StyledLink>
                <CharacterCounter
                    hasCharacterCount={hasCharacterCount}
                    value={value}
                    maxLength={maxLength}
                />
            </div>
        );
    }

    if (fieldSubLeft || fieldSubRight) {
        fieldSub = (
            <div className="eds-field__sub">
                {fieldSubLeft}
                {fieldSubRight}
            </div>
        );
    }

    return fieldSub;
};

export default class FieldWrapper extends PureComponent {
    static propTypes = {
        /**
         * The amount of spacing for the bottom
         */
        bottomSpacing: PropTypes.number.isRequired,

        /**
         * FieldWrapper should be provided with a single element.
         */
        children: PropTypes.element.isRequired,

        /**
         * Text to be displayed as the label of the input element.
         * Required for accessibility reasons but it can be hidden.
         */
        label: PropTypes.node.isRequired,

        /**
         * Text to be displayed as annotation below the input element.
         */
        annotationNote: PropTypes.node,

        /**
         * Sets disabled attribute to the input element.
         */
        disabled: PropTypes.bool,

        /**
         * Defines whether the field should hide or display an static or animated label.
         * By default it will display an animated label.
         */
        style: STYLES_PROP_TYPES,

        /**
         * Field border type chosen for the input. It can be one of: 'default', 'simple' or 'none'.
         * Default: the border changes from grey on idle to a coloured state on focus.
         * Simple: the border preserves its grey colour and has no rounded corners.
         * None: the border is hidden and is replaced by a line below the input to point the writtable area.
         */
        borderType: BORDER_TYPES_PROP_TYPES,

        /**
         * Sets error state (adds error classes and aria-invalid).
         */
        hasError: PropTypes.bool,

        /**
         * Sets the id for the input element and htmlFor attribute for the label.
         */
        id: PropTypes.string,

        /**
         * Changes the input field for a textarea
         */
        isMultiline: PropTypes.bool,

        /**
         * Marks the input as required (adds * and aria-required).
         */
        required: PropTypes.bool,

        /**
         * Sets the max length of the character counter and also limits the
         * number of characters you can input.
         */
        maxLength: PropTypes.number,

        /**
         * Toggles a display of the current character count against the maximum characters allowed in the field.
         */
        hasCharacterCount: PropTypes.bool,

        /**
         * Text for the link to display.
         */
        linkText: PropTypes.string,

        /**
         * Source url of the link to display.
         */
        linkSrc: PropTypes.string,

        /**
         * Node to be displayed to the left of the input
         */
        prefix: PropTypes.node,

        /**
         * Node to be displayed to the right of the input.
         */
        suffix: PropTypes.node,

        /**
         * Value of text input
         * The value can override the default value if specified
         * After the text input has been rendered the first time,
         * if the value changes, it *will* update the input.
         */
        value: PropTypes.string,

        /**
         * Active state of the input field
         */
        isActive: PropTypes.bool,

        /**
         * Needed for inputs that use an aside element (both prefix and suffix)
         * that is bigger than the normal text size. If the content (maybe a button or icon)
         * is getting cropped on the sides, this lowers the margin allowing it to
         * center within the aside again.
         *
         * NOTE: This property is deprecated in favor of minAsideSpacing.
         */
        hasMinAsideSpacing: PropTypes.bool,

        /**
         * Needed for inputs that use an aside element (either prefix, suffix or both)
         * that is bigger than the normal text size. If the content (maybe a button or icon)
         * is getting cropped on the sides, this lowers the margin allowing it to
         * center within the aside again.
         */
        minAsideSpacing: MIN_ASIDE_SPACING_PROP_TYPES,

        /**
         * Identifier for tests.
         */
        dataSpec: PropTypes.string,

        /**
         * Identifier for tests.
         */
        dataTestid: PropTypes.string,

        /**
         * Automation identifier. We need a data-automation tag targeting field wrappers so we can associate
         * labels, fields, error messages, etc. without using markup-dependent sibling selectors
         */
        dataAutomation: PropTypes.string,

        /**
         * Changes the input field for a textarea that auto-grows when the user types
         * Until it reaches 10 lines
         */
        isResizable: PropTypes.bool,

        /**
         * Converts aside into clickable element that focuses on input field onclick
         */
        isAsideClickable: PropTypes.bool,
    };

    static defaultProps = {
        dataSpec: 'field-gradient',
        isResizable: false,
    };

    // We want to make sure the the label doesn't respond to mousedown events
    // In cases where the label is styled to overlap an input we essentially want to ignore
    // those events so they don't interfere with the underlapped element
    _handleMouseDown(e) {
        e.preventDefault();
    }

    _generateAnonymizedString(val) {
        return val
            .split('')
            .map(() => 'x')
            .join('');
    }

    render() {
        const {
            annotationNote,
            bottomSpacing,
            children,
            dataSpec,
            dataAutomation,
            dataTestid,
            disabled,
            style,
            borderType,
            hasError,
            id,
            isActive,
            isMultiline,
            label,
            required,
            suffix,
            prefix,
            value,
            hasMinAsideSpacing,
            minAsideSpacing,
            maxLength,
            hasCharacterCount,
            linkText,
            linkSrc,
            placeholder,
            isResizable,
            isAsideClickable,
        } = this.props;
        const hasSimpleBorder = borderType === BORDER_SIMPLE;
        const isBorderless = borderType === BORDER_NONE;
        const isBorderHidden = borderType === BORDER_HIDDEN;
        const isContentDrivenBorder = borderType === BORDER_CONTENT_DRIVEN;
        const shouldHideLabel = style === STYLE_BASIC;
        const shouldShowAsStatic = isStatic({
            style,
            value,
            hasError,
            isActive,
        });
        const containerClassName = classNames('eds-field-styled', {
            'eds-field-styled--disabled': disabled,
            'eds-field-styled--basic': shouldHideLabel,
            'eds-field-styled--static': shouldShowAsStatic,
            'eds-field-styled--focus': isActive && !hasSimpleBorder,
            'eds-field-styled--error': hasError,
            'eds-field-styled--borderless': isBorderless,
            'eds-field-styled--content-driven-border': isContentDrivenBorder,
            'eds-field-styled--hidden-border': isBorderHidden,
        });

        const borderSimulationClassName = classNames(
            'eds-field-styled__border-simulation',
            {
                'eds-field-styled__border-simulation--simple': hasSimpleBorder,
                'eds-field-styled__border-simulation--empty':
                    isBorderless || isContentDrivenBorder,
            },
        );
        const inputContainerClassName = classNames(
            'eds-field-styled__input-container',
            {
                'eds-field-styled__input-container--multiline':
                    !shouldHideLabel && isMultiline && !disabled,
            },
            {
                'eds-field-styled__input-container--multiline-resizable':
                    isMultiline && !disabled && isResizable,
            },
        );
        const prefixHasMinAsideSpacing =
            hasMinAsideSpacing ||
            minAsideSpacing === MIN_ASIDE_SPACING_PREFIX ||
            minAsideSpacing === MIN_ASIDE_SPACING_BOTH;
        const suffixHasMinAsideSpacing =
            hasMinAsideSpacing ||
            minAsideSpacing === MIN_ASIDE_SPACING_SUFFIX ||
            minAsideSpacing === MIN_ASIDE_SPACING_BOTH;

        return (
            <div
                className={containerClassName}
                style={{ marginBottom: `${bottomSpacing * 4}px` }}
                data-automation={dataAutomation}
                data-testid={dataTestid}
                data-spec={dataSpec}
            >
                <div className={borderSimulationClassName}>
                    <div className="eds-field-styled__internal">
                        <AsideElement
                            prefix={prefix}
                            hasError={hasError}
                            hasMinAsideSpacing={prefixHasMinAsideSpacing}
                            htmlFor={isAsideClickable ? id : null}
                        />
                        <div
                            className={inputContainerClassName}
                            data-val={
                                isContentDrivenBorder
                                    ? this._generateAnonymizedString(value) ||
                                      this._generateAnonymizedString(
                                          placeholder?.toString?.(),
                                      ) ||
                                      this._generateAnonymizedString(
                                          placeholder,
                                      )
                                    : null
                            }
                        >
                            <div className="eds-field-styled__label-wrapper">
                                <Label
                                    id={`${id}-label`}
                                    hidden={shouldHideLabel}
                                    htmlFor={id}
                                    required={required}
                                    onMouseDown={this._handleMouseDown.bind(
                                        this,
                                    )}
                                    shouldShowAsStatic={shouldShowAsStatic}
                                >
                                    {label}
                                </Label>
                            </div>
                            {children}
                        </div>
                        <AsideElement
                            suffix={suffix}
                            hasError={hasError}
                            hasMinAsideSpacing={suffixHasMinAsideSpacing}
                        />
                    </div>
                </div>

                <FieldSub
                    annotationNote={annotationNote}
                    linkSrc={linkSrc}
                    linkText={linkText}
                    hasCharacterCount={hasCharacterCount}
                    value={value}
                    maxLength={maxLength}
                />
            </div>
        );
    }
}
