import { Divider } from '@eventbrite/eds-divider';
import { gettext } from '@eventbrite/i18n';
import {
    MagnifyingGlassIcon,
    MapPinIcon,
    Typography,
} from '@eventbrite/marmalade';
import {
    LocationProvider,
    useLocationContext,
} from '@eventbrite/search-takeover';
import { logEvent } from '@eventbrite/statsig';
import loadable from '@loadable/component';
import React, { useState } from 'react';
import { STATSIG_SEARCH_BAR_CLICK } from '../../../../constants';
import { useGlobalNavContext } from '../../../../state';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../utils/heap-data';
import styles from './ResultsPageSearchBar.module.scss';

const LazySearchTakeover = loadable.lib(
    () => import('@eventbrite/search-takeover'),
);

export const ResultsPageSearchBarContent = () => {
    const { location, isUsingUserLocation } = useLocationContext();
    const { Teams, Features, Components, Actions } = HeapAttributes;
    const currentLocationString = 'Current location';
    const { searchBar } = useGlobalNavContext();

    const [showSearch, setShowSearch] = useState(false);

    const handleClick = () => {
        logEvent(STATSIG_SEARCH_BAR_CLICK);
        setShowSearch(true);
    };
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            logEvent(STATSIG_SEARCH_BAR_CLICK);
            setShowSearch(true);
        }
    };
    function handleClose() {
        setShowSearch(false);
    }

    if (!searchBar?.renderSearchBar) {
        return null;
    }

    return (
        <div
            className={styles.resultsPageSearchBarWrapper}
            data-testid="header-search"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            aria-label={gettext('open search bar').toString()}
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.GlobalNav,
                component: Components.SearchBar,
                name: 'search-bar',
                action: Actions.Click,
            })}
        >
            <Typography
                variant="body-md"
                as="span"
                color="neutral-700"
                className={styles.resultsPageSearchBarContent}
                onClick={handleClick}
            >
                <div className={styles.searchLabelContainer}>
                    <MagnifyingGlassIcon size="small" />
                    <div className={styles.labelsWrapper}>
                        <span className={styles.desktopLabel}>
                            {searchBar?.desktopLabel}
                        </span>
                        <span className={styles.mobileLabel}>
                            {searchBar?.mobileLabel}
                        </span>
                        <span className={styles.mobileLocation}>
                            {isUsingUserLocation
                                ? currentLocationString
                                : `in ${location?.currentPlace}`}
                        </span>
                    </div>
                </div>
                <div className={styles.dividerContainer}>
                    <Divider direction="vertical" color="ui-500" />
                </div>
                <div className={styles.locationContainer}>
                    <MapPinIcon size="small" />
                    {isUsingUserLocation
                        ? currentLocationString
                        : location?.currentPlace}
                </div>
            </Typography>
            {showSearch ? (
                <LazySearchTakeover data-testid="search-takeover">
                    {({ SearchTakeover }: { SearchTakeover: any }) => (
                        <SearchTakeover
                            domNodeId="root"
                            onClose={handleClose}
                            id="search-takeover"
                            isVisible={showSearch}
                        />
                    )}
                </LazySearchTakeover>
            ) : null}
        </div>
    );
};

const ResultsPageSearchBar = () => {
    return (
        <div>
            <LocationProvider>
                <ResultsPageSearchBarContent />
            </LocationProvider>
        </div>
    );
};

export default ResultsPageSearchBar;
